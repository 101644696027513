import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FormControl, Overlay, Popover } from 'react-bootstrap';

import DealNumberFormat from '@core/models/DealNumberFormat';
import Section from '@core/models/Section';
import { NUMBER_TYPES, formatOrder } from '@core/utils/OrderFormatter';

import { Button, Dropdown, MenuItem, Switch } from '@components/dmp';

@autoBindMethods
export default class CustomNumbering extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    section: PropTypes.instanceOf(Section),
    target: PropTypes.object,
    container: PropTypes.object,
    hide: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const format = props.section.numberFormat;

    this.state = {
      pre: _.get(format, 'pre', ''),
      post: _.get(format, 'post', ''),
      case: _.get(format, 'case', null),
      type: _.get(format, 'type', 'number'),
      zeroPad: _.get(format, 'zeroPad', false),
      continueNumericPrefix: _.get(format, 'continueNumericPrefix', true),
      newNumberingLevel: _.get(format, 'newNumberingLevel', false),
    };
  }

  focus() {
    const el = this.refPrefix;
    if (el) el.focus();
  }

  close() {
    const { hide } = this.props;

    this.setState({
      pre: '',
      post: '',
      case: null,
      type: 'number',
      zeroPad: false,
      continueNumericPrefix: true,
      newNumberingLevel: false,
    });
    hide();
  }

  save() {
    const { onSave } = this.props;
    const newStyle = _.pick(this.state, [
      'pre',
      'post',
      'case',
      'type',
      'zeroPad',
      'continueNumericPrefix',
      'newNumberingLevel',
    ]);

    onSave(new DealNumberFormat(newStyle));
    this.close();
  }

  get example() {
    const fmt = new DealNumberFormat(this.state);
    return fmt.example;
  }

  render() {
    const { hide, target, container, section, id } = this.props;
    const { pre, post, type, zeroPad, continueNumericPrefix, newNumberingLevel } = this.state;

    const Example = () => {
      let sec = section;
      const master = section.deal.root;

      const pieces = [];

      while (sec.id !== master.id) {
        // Merge with state for preview
        const active = (sec.active = sec.id === section.id);

        if (active) {
          sec.numberFormat.pre = pre;
          sec.numberFormat.post = post;
          sec.numberFormat.type = type;
          sec.numberFormat.zeroPad = zeroPad;
          sec.numberFormat.case = this.state.case;
          sec.numberFormat.continueNumericPrefix = continueNumericPrefix;
          sec.numberFormat.newNumberingLevel = newNumberingLevel;
        }

        pieces.push(sec);

        sec = sec.sourceParent;
      }

      return (
        <div className="example-container">
          <div className="example-box">
            <div className="example">
              {pieces.reverse().map((piece, i) => {
                let margin = i,
                  ghost = '',
                  example;

                if (i > 0) {
                  for (var x = i; x > 0; x--) {
                    const displayNumber = pieces[x - 1].displayNumber;

                    if (displayNumber) {
                      ghost += displayNumber;
                    } else {
                      margin++;
                    }
                  }
                }

                if (piece.type === 'unordered') {
                  const style = find(NUMBER_STYLES, { pre: piece.pre });
                  example = get(style, 'description', piece.pre);
                } else if (piece.type === 'none' || !piece.displayNumber) {
                  example = '';
                } else {
                  example = piece.displayNumber;
                }

                return (
                  <div
                    key={piece.id}
                    className={`example-row ${piece.active ? 'active' : ''}`}
                    style={{ paddingLett: `${margin}rem` }}
                  >
                    <div>
                      {ghost ? <span className="no-visibility">{ghost}</span> : ''}
                      {example}
                    </div>
                    <div className="line"></div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    };

    let title = `Level ${section.numberingLevel + 1} Numbering `;
    if (section.appendix) title += `(${section.appendix.displayname})`;

    // A NUMBER_TYPE should now always be found per fixes in DealNumberFormat.constructor,
    // But fallback to top-level numbering just to be safe and avoid a React error
    const match = { type, case: this.state.case };
    if (zeroPad) match.zeroPad = true;

    const selectedType = _.find(NUMBER_TYPES, match) || NUMBER_TYPES[0];

    return (
      <Overlay
        onEntered={this.focus}
        onHide={hide}
        placement="bottom"
        rootClose
        show={true}
        target={target}
        container={container}
      >
        <Popover className="popover-custom-numbering" id={`${id}-pop`}>
          <h3 className="popover-title">{title}</h3>
          <div className="number-style">
            <div>
              <small>
                <label htmlFor="prefix">Prefix</label>
              </small>
              <FormControl
                id="prefix"
                onChange={(e) => this.setState({ pre: e.target.value })}
                inputRef={(r) => (this.refPrefix = r)}
                type="text"
                value={pre || ''}
                bsSize="small"
                autocomplete="off"
              />
            </div>
            <div>
              <div className="dropdown">
                <small>
                  <label htmlFor={`${id}-dd`}>Number Format</label>
                </small>
              </div>
              <Dropdown
                id={`${id}-dd`}
                noCaret
                onSelect={(t) => this.setState({ type: t.type, case: t.case, zeroPad: t.zeroPad })}
                title={selectedType.label}
                size="small"
                style={{ minWidth: '10rem' }}
              >
                {NUMBER_TYPES.map((t, idx) => (
                  <MenuItem key={idx} eventKey={t}>
                    {t.label}
                  </MenuItem>
                ))}
              </Dropdown>
            </div>
            <div>
              <small>
                <label htmlFor="suffix">Suffix</label>
              </small>
              <FormControl
                id="suffix"
                onChange={(e) => this.setState({ post: e.target.value })}
                placeholder="Suffix"
                type="text"
                value={post || ''}
                bsSize="small"
                autocomplete="off"
              />
            </div>
          </div>
          <div className="number-example">
            <small>
              <label htmlFor="example">Example</label>
            </small>

            <Example />
          </div>
          <div>
            <Switch
              checked={continueNumericPrefix}
              onChange={() => this.setState({ continueNumericPrefix: !continueNumericPrefix })}
              disabled={section.numberingLevel === 0}
              size="small"
            >
              Include prefix from parent
            </Switch>
          </div>
          <div>
            <Switch
              checked={newNumberingLevel}
              onChange={() => this.setState({ newNumberingLevel: !newNumberingLevel })}
              disabled={section.numberingLevel === 0}
              size="small"
            >
              Start new numbering level
            </Switch>
          </div>
          <div className="actions">
            <Button size="small" dmpStyle="link" className="cancel" onClick={this.close}>
              Cancel
            </Button>
            <Button className="save" size="small" onClick={this.save}>
              Save
            </Button>
          </div>
        </Popover>
      </Overlay>
    );
  }
}
