import { assign, forEach, forIn, get, omit, sortBy } from 'lodash';

import AITeamConfig from './AITeamConfig';
import { DEFAULT_COLOR } from './Branding';
import Branding from './Branding';
import CheckpointGroup from './CheckpointGroup';
import FilterStore from './FilterStore';
import ReportStore from './ReportStore';
import Workflow from './Workflow';

export const TEAM_ROLES = {
  VIEWER: {
    title: 'Viewer',
    value: 'viewer',
    description: 'Viewers can use the available templates but cannot edit or create new ones',
  },
  EDITOR: {
    title: 'Editor',
    value: 'editor',
    description: 'Editors can edit and create new templates',
  },
  OWNER: {
    title: 'Owner',
    value: 'owner',
    description: 'Owners can edit and create new templates and can manage members of this team',
  },
};

// Use same pattern as @models/User.js to define features that are enabled/disabled by default.
export const FEATURES = {
  OKTA_OIDC: { key: 'oktaOIDC', defaultValue: false, header: 'Okta', isAdminEditable: false },
  API: { key: 'api', defaultValue: false, header: 'API', isAdminEditable: true },
  FILEVINE: { key: 'filevine', defaultValue: false, header: 'Filevine', isAdminEditable: true },
  WORKFLOWS: { key: 'workflows', defaultValue: true, header: 'Workflows', isAdminEditable: true },
  CHECKPOINT_GROUPS: {
    key: 'checkpointGroups',
    defaultValue: true,
    header: 'Checkpoint Groups',
    isAdminEditable: true,
  },
  SEARCH_AFTER: { key: 'searchAfter', defaultValue: false, header: 'API', isAdminEditable: false },
  LENS: { key: 'lenses', defaultValue: false, header: 'Lenses', isAdminEditable: true },
  WEBHOOKS: { key: 'webhooks', defaultValue: false, header: 'Webhooks', isAdminEditable: true },
  INBOUND: { key: 'inbound', defaultValue: false, header: 'Inbound', isAdminEditable: true },
  VINE_AI: { key: 'vineAI', defaultValue: false, header: 'Vine AI', isAdminEditable: true },
  TIMELINE_AI: { key: 'timelineAI', defaultValue: false, header: 'Timeline AI', isAdminEditable: true },
  SERRVICE_PROVIDERS: {
    key: 'serviceProviders',
    defaultValue: false,
    header: 'Service Providers',
    isAdminEditable: true,
  },
  PDF_EXTRACTION: { key: 'pdfExtraction', defaultValue: false, header: 'pdfExtraction', isAdminEditable: false },
  NUMBER_ALIGNMENT_OVERRIDES: {
    key: 'numberAlignmentOverrides',
    defaultValue: false,
    header: 'Number Alignment Overrides',
    isAdminEditable: true,
  },
  INDENTATION: { key: 'indentation2', defaultValue: false, header: 'Indentation 2.0', isAdminEditable: true },
};

export default class Team {
  teamID = null;
  info = {
    address: null,
    brandColor: DEFAULT_COLOR,
    created: null,
    updated: null,
    legalName: null,
    logo: null,
    name: null,
    addressProperties: {},
  };
  users = {};
  themes = {};
  workflows = [];
  checkpointGroups = [];
  reports = null;
  filters = null;

  // This gets populated by merging any custom values (from db) into the defaults defined above
  features = {};

  aiConfig = null;

  constructor(json) {
    assign(this, omit(json, 'reports', 'filters', 'workflows'));
    this.reports = new ReportStore(json.reports);
    this.filters = new FilterStore(json.filters);

    const workflows = [];
    forEach(json.workflows, (wf) =>
      workflows.push(new Workflow({ ...wf, teamCheckpointGroups: json.checkpointGroups }))
    );
    this.workflows = sortBy(workflows, 'name');

    const checkpointGroups = [];
    forEach(json.checkpointGroups, (checkpointGroup) => checkpointGroups.push(new CheckpointGroup(checkpointGroup)));
    this.checkpointGroups = sortBy(checkpointGroups, 'name');

    // Override default feature-set with any provided features we find in db
    const features = {};
    forEach(FEATURES, (feature) => {
      features[feature.key] = get(json, `features[${feature.key}]`, feature.defaultValue);
    });
    this.features = features;

    this.aiConfig = new AITeamConfig(get(json, 'aiConfig', null), this);
  }

  isFeatureActive(feature) {
    return feature && !!get(this.features, feature.key);
  }

  //returns a array of team owner ids
  get teamOwners() {
    let owners = [];
    forIn(this.users, (value, key) => {
      if (value === TEAM_ROLES.OWNER.value) {
        owners.push(key);
      }
    });
    return owners;
  }

  get hasWorkflows() {
    return get(this, 'workflows.length') > 0;
  }

  get branding() {
    return new Branding(this.info);
  }
}
