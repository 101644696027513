import React from 'react';

import cx from 'classnames';

import { ButtonGroup, ControlLabel, FormGroup } from 'react-bootstrap';

import IndentationStyle, { INDENT_TYPES, INDENT_WRAPS } from '@core/models/IndentationStyle';
import { ALIGN } from '@core/models/SectionStyle';

import { Button, Dropdown, Form, MenuItem, Switch } from '@components/dmp';

import TooltipButton from '@components/editor/TooltipButton';

const IndentationStyleEditor = ({ levelIndent, onSave, onReset, alignment }) => {
  const i = levelIndent?.level;

  const [showResetConfirm, setShowResetConfirm] = React.useState(false);

  let activeType = levelIndent?.type ?? INDENT_TYPES.NONE;
  const activeOverride = levelIndent?.override;
  const activeWrap = INDENT_WRAPS[levelIndent?.wrap] || INDENT_WRAPS[i - 1];
  const activeForcedLevel = INDENT_WRAPS[levelIndent?.forcedLevel] || INDENT_WRAPS[0];
  const isCentered = alignment === ALIGN.CENTER;
  console.log('IndentationStyleEditor', {
    i,
    levelIndent,
    activeType,
    activeOverride,
    activeWrap,
    activeForcedLevel,
    INDENT_WRAPS,
    alignment,
  });

  if (isCentered) activeType = INDENT_TYPES.NONE;

  const handleOnSave = (property, level, value) => {
    const indentation = {};

    switch (property) {
      case 'type':
        indentation.type = value;
        indentation.wrap = value === INDENT_TYPES.INDENT_HANGING ? Math.min(level, 8) : level - 1;
        if (value === INDENT_TYPES.INDENT_FIRST_LINE) indentation.wrap = level - 1;
        break;
      case 'wrap':
        indentation.wrap = value;
        break;
      case 'override':
        indentation.override = value;
        break;
      case 'forcedLevel':
        indentation.forcedLevel = value;
        break;
    }

    onSave ? onSave(level, indentation) : console.log('onSave not implemented', { level, indentation });
  };

  const handleReset = (level) => {
    const indentation = new IndentationStyle({ level });
    indentation.wrap = level - 1;

    setShowResetConfirm(false);
    onReset ? onReset(indentation, level) : console.log('onReset not implemented', { indentation, level });
  };

  const inThemeMode = !!alignment; // We can do this, because the theme editor has no implementation, so alignment will never be populated here

  return (
    <div
      key={`indentation-${i}`}
      className={cx({ active: showResetConfirm === i }, 'page-style-container margins indentation')}
      data-cy="page-style-indentation"
    >
      <div className="page-style-title" data-cy="page-style-title">
        <span>Level {i}</span>
        <span className="description">
          {showResetConfirm === i && (
            <>
              <div>
                Are you sure you want to reset this level to default settings?
                <span>
                  {' '}
                  <i>{inThemeMode ? 'Theme' : 'Template-level'} settings will be preserved.</i>
                </span>
              </div>
              <Button size="small" dmpStyle="link" onClick={() => setShowResetConfirm(false)} style={{ padding: 0 }}>
                Go back
              </Button>
              &nbsp;
            </>
          )}
          {
            <Button
              size="small"
              dmpStyle="link"
              onClick={() => (showResetConfirm ? handleReset(i) : setShowResetConfirm(i))}
              style={{ padding: 0 }}
            >
              Reset
            </Button>
          }
        </span>
      </div>
      <div className="page-style-body indentation">
        <Form>
          <div className="d-flex flex-row" style={{ alignItem: 'flex-start' }}>
            <FormGroup className="complex d-flex flex-column">
              <ControlLabel>Type</ControlLabel>
              <ButtonGroup>
                <TooltipButton tip="Default">
                  <Button
                    iconOnly
                    icon="indentNone"
                    size="small"
                    onClick={() => handleOnSave('type', i, INDENT_TYPES.NONE)}
                    className={cx({ active: activeType === INDENT_TYPES.NONE })}
                  />
                </TooltipButton>

                <TooltipButton tip="First Line">
                  <Button
                    iconOnly
                    icon="indentFirstLine"
                    size="small"
                    onClick={() => handleOnSave('type', i, INDENT_TYPES.INDENT_FIRST_LINE)}
                    className={cx({ active: activeType === INDENT_TYPES.INDENT_FIRST_LINE })}
                    disabled={isCentered}
                  />
                </TooltipButton>

                <TooltipButton tip="Hanging">
                  <Button
                    iconOnly
                    icon="indentHanging"
                    size="small"
                    onClick={() => handleOnSave('type', i, INDENT_TYPES.INDENT_HANGING)}
                    className={cx({ active: activeType === INDENT_TYPES.INDENT_HANGING })}
                    disabled={isCentered}
                  />
                </TooltipButton>
              </ButtonGroup>
            </FormGroup>

            <FormGroup style={{ width: '15rem' }} className="complex d-flex flex-column">
              <ControlLabel>Wrap</ControlLabel>
              <Dropdown
                id="margin-dropdown"
                onSelect={(wrap) => handleOnSave('wrap', i, wrap)}
                title={activeWrap?.title}
                block
                size="small"
                disabled={isCentered}
              >
                {_.map(INDENT_WRAPS, (wrap, idx) => (
                  <MenuItem key={idx} eventKey={wrap.key}>
                    {wrap.title}
                  </MenuItem>
                ))}
              </Dropdown>
            </FormGroup>
          </div>
          <div
            className="control-row"
            style={{
              borderTop: activeOverride && !isCentered ? '1px solid #e5e5e5' : '1px solid transparent',
              paddingTop: '1rem',
            }}
          >
            <FormGroup className="d-flex flex-column align-items-left">
              <Switch
                checked={activeOverride}
                id="border"
                onChange={(value) => handleOnSave('override', i, value)}
                size="small"
                disabled={isCentered}
              >
                Override Level
              </Switch>
              {activeOverride && !isCentered && (
                <Dropdown
                  id="margin-dropdown"
                  onSelect={(wrap) => handleOnSave('forcedLevel', i, wrap)}
                  title={activeForcedLevel?.title}
                  block
                  size="small"
                  disabled={!activeOverride}
                  style={{ width: '15rem' }}
                >
                  {_.map(
                    INDENT_WRAPS.filter((w) => w.key !== 'none'),
                    (wrap, idx) => (
                      <MenuItem key={idx} eventKey={wrap.key}>
                        {wrap.title}
                      </MenuItem>
                    )
                  )}
                </Dropdown>
              )}
            </FormGroup>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default IndentationStyleEditor;
