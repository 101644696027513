import { filter, find, get, merge } from 'lodash';

// Added key property to map to types used in docx npm library
export const NUMBER_TYPES = [
  { key: 'decimal', type: 'number', label: '1, 2, 3', case: null },
  { key: 'decimal', type: 'number', label: '01, 02, 03', case: null, zeroPad: true },
  { key: 'lowerRoman', type: 'roman', label: 'i, ii, iii', case: 'lower' },
  { key: 'upperRoman', type: 'roman', label: 'I, II, III', case: 'upper' },
  { key: 'lowerLetter', type: 'alpha', label: 'a, b, c', case: 'lower' },
  { key: 'upperLetter', type: 'alpha', label: 'A, B, C', case: 'upper' },
];

// List of pre-defined standard permutations of list styles
// This is used to populate a style dropdown in SectionToolbar,
// But also to create standard doc styles (eg in docx rendering)
export const NUMBER_STYLES = [
  { header: 'Numbered Styles' },

  { label: '1. 2. 3. 4.', pre: null, post: '.', type: 'number' },
  { label: '1) 2) 3) 4)', pre: null, post: ')', type: 'number' },
  { label: '(1) (2) (3) (4)', pre: '(', post: ')', type: 'number' },
  { label: '01. 02. 03. 04.', pre: null, post: '.', zeroPad: true, type: 'number' },

  {
    label: 'I. II. III. IV.',
    pre: null,
    post: '.',
    type: 'roman',
    case: 'upper',
  },
  {
    label: 'I) II) III) IV)',
    pre: null,
    post: ')',
    type: 'roman',
    case: 'upper',
  },
  {
    label: '(I) (II) (III) (IV)',
    pre: '(',
    post: ')',
    type: 'roman',
    case: 'upper',
  },

  {
    label: 'i. ii. iii. iv.',
    pre: null,
    post: '.',
    type: 'roman',
    case: 'lower',
  },
  {
    label: 'i) ii) iii) iv)',
    pre: null,
    post: ')',
    type: 'roman',
    case: 'lower',
  },
  {
    label: '(i) (ii) (iii) (iv)',
    pre: '(',
    post: ')',
    type: 'roman',
    case: 'lower',
  },

  { label: 'A. B. C. D.', pre: null, post: '.', type: 'alpha', case: 'upper' },
  { label: 'A) B) C) D)', pre: null, post: ')', type: 'alpha', case: 'upper' },
  {
    label: '(A) (B) (C) (D)',
    pre: '(',
    post: ')',
    type: 'alpha',
    case: 'upper',
  },

  { label: 'a. b. c. d.', pre: null, post: '.', type: 'alpha', case: 'lower' },
  { label: 'a) b) c) d)', pre: null, post: ')', type: 'alpha', case: 'lower' },
  {
    label: '(a) (b) (c) (d)',
    pre: '(',
    post: ')',
    type: 'alpha',
    case: 'lower',
  },

  { divider: true },
  { header: 'Unordered Styles' },

  { label: '●', pre: '●', type: 'unordered', description: 'Bullet' },
  { label: '○', pre: '○', type: 'unordered', description: 'Circle' },
  { label: '-', pre: '-', type: 'unordered', description: 'Hyphen' },
  { label: '–', pre: '–', type: 'unordered', description: 'En-dash' },

  { divider: true },
  { label: 'Custom...', pre: null, post: '.', type: 'number', custom: true },
];

export const FOOTNOTE_NUMBER_STYLES = [
  { label: '1, 2, 3, ...', pre: null, post: null, type: 'number' },
  { label: 'a, b, c, ...', pre: null, post: null, type: 'alpha', case: 'lower' },
  { label: 'A, B, C, ...', pre: null, post: null, type: 'alpha', case: 'upper' },
  {
    label: 'i, ii, iii, ...',
    pre: null,
    post: null,
    type: 'roman',
    case: 'lower',
  },
  {
    label: 'I, II, III, ...',
    pre: null,
    post: null,
    type: 'roman',
    case: 'upper',
  },
];

const isDecimal = (num) => /^[0-9]+$/.test(num);
const isRoman = (num) => /^[IVX]+$/i.test(num);
const isAlpha = (num) => /^[a-z]+$/i.test(num);

const OrderFormatter = {
  number: (index) => {
    return index + 1;
  },

  //http://stackoverflow.com/questions/3145030/convert-integer-into-its-character-equivalent-in-javascript
  alpha: (index) => {
    var s = '';
    while (index >= 0) {
      s += String.fromCharCode(97 + (index % 26));
      index -= 26;
    }
    return s;
  }, //only works for up to 26 letters...

  //http://stackoverflow.com/questions/9083037/convert-a-number-into-a-roman-numeral-in-javascript
  roman: (index) => {
    var num = index + 1;

    var lookup = {
        M: 1000,
        CM: 900,
        D: 500,
        CD: 400,
        C: 100,
        XC: 90,
        L: 50,
        XL: 40,
        X: 10,
        IX: 9,
        V: 5,
        IV: 4,
        I: 1,
      },
      roman = '',
      i;
    for (i in lookup) {
      while (num >= lookup[i]) {
        roman += i;
        num -= lookup[i];
      }
    }
    return roman;
  },
};

// This is no longer used but left here for future reference;
// docx numbering is converted to Outlaw format upon ingestion
// Uhhm.... let give it 6 months.
const docxOrder = (index, format, sourceSection) => {
  // Get the section's full numbering system (i.e., all levels)
  const formats = get(sourceSection, 'appendix.style.numbering', sourceSection.deal.style.numbering);

  // Use regex to build string append each piece
  // Docx formats are like this: %1.%2
  // Where the number following the % is a 1-based indent level
  const DOCX_REG = /([-.:();]*)%([\d])([-.:();]*)/gi;
  let match = null,
    rendered = '';
  while ((match = DOCX_REG.exec(format.docx)) !== null) {
    const level = parseInt(match[2]) - 1;
    // TODO: error handling
    const fmt = merge({}, formats[level], {
      pre: match[1],
      post: match[3],
      docx: null,
    });

    // We need to traverse up the levels to find the corresponding section that the level points to
    let targetSection = sourceSection,
      idx = -1;
    while (targetSection.sourceParent && targetSection.indentLevel > level) targetSection = targetSection.sourceParent;
    // And find the index of THAT section within its siblings
    idx = filter(targetSection.sourceParent.sourceChildren, {
      showOrder: true,
      passesConditions: true,
    }).indexOf(targetSection);

    rendered += docxOrder(idx, fmt);
  }
  return rendered;
};

export function formatOrder(index, format, opts = {}) {
  // Bullet (unordered) styles simply have a single bullet character in the 'pre' property -- that's all we need!
  if (format.type === 'unordered') return format.pre;
  if (format.type === 'none') return '';

  const renderer = OrderFormatter[format.type];
  if (!renderer) return '';

  let val = renderer(index);
  if (format.type !== 'number') {
    switch (format.case) {
      case 'upper':
        val = val.toUpperCase();
        break;
      case 'lower':
      case null:
      case undefined:
      default:
        val = val.toLowerCase();
        break;
    }
  }

  if (val && format.type === 'number' && format.zeroPad) {
    if (val.toString().length === 1) {
      val = val.toString().padStart(2, '0');
    }
  }

  const pre = (!opts.skipPrefix && format.pre) || '';
  const post = (!opts.skipNumbering && format.post) || '';

  if (opts.skipNumbering) {
    val = '';
  }

  const txt = `${pre}${val || ''}${post}`;

  return txt;
}

//attempt to convert a string like "5" or "iii" or "C" to a 0-based index
export function discoverOrder(string) {
  if (isDecimal(string)) return parseInt(string) - 1;

  //IVX gets us to 39 in roman numerals
  //impossible to differentiate between I (meaning 1) and I (meaning 9th letter of alphabet)
  //but we're giving roman numerals precedent here
  if (isRoman(string)) {
    //do reverse of above. simpler to just count up from 0 than writing a reverse algorithm...
    for (var i = 0; i < 39; i++) {
      if (OrderFormatter.roman(i) == string.toUpperCase()) return i;
    }
  }

  //only support up to 2 letters if candidate number is letter, e.g., "AA."
  if (string.length <= 2 && isAlpha(string)) {
    //do reverse of above. simpler to just count up from 0 than writing a reverse algorithm...
    for (var i = 0; i < 52; i++) {
      if (OrderFormatter.alpha(i) == string.toLowerCase()) return i;
    }
  }

  //return -1 meaning not a number if none of the above checks match
  return -1;
}

export function discoverNumberType(num) {
  let numCase;

  if (isDecimal(num)) return find(NUMBER_TYPES, { key: 'decimal' });
  else if (isRoman(num)) {
    numCase = num.toUpperCase() == num ? 'upper' : 'lower';
    return find(NUMBER_TYPES, { key: `${numCase}Roman` });
  } else if (isAlpha(num)) {
    numCase = num.toUpperCase() == num ? 'upper' : 'lower';
    return find(NUMBER_TYPES, { key: `${numCase}Letter` });
  }

  return null;
}

// I <3 internet https://stackoverflow.com/a/13627586
export function getOrdinalSuffix(i) {
  const j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return 'st';
  }
  if (j === 2 && k !== 12) {
    return 'nd';
  }
  if (j === 3 && k !== 13) {
    return 'rd';
  }
  return 'th';
}
